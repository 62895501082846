import "./Navbar.css";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useParams, useLocation, Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import Logo from "../Logo";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import clearAllCookies from "../../packages/cookiesPackage/ClearAllCookies";
import fetchUpcommingGeb from "../../packages/fetchUpcommingGeb";
import { setUpcommingGeb } from "../../state/GebEdition/gebEditionActions";
import { setPastGeb } from "../../state/GebEdition/gebEditionActions";
import fetchPastGeb from "../../packages/fetchPastGeb";
import { GetUser } from "../../packages/cookiesPackage/ManageUserState";

const AdminNavbar = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();

  const [selectedItem, setSelectedItem] = useState(0);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOpen2, setDropdownOpen2] = useState(false);
  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const handleDropdownToggle2 = () => {
    setDropdownOpen2(!dropdownOpen2);
  };
  const user = GetUser()
  const currentGeb = useSelector((state) => state.gebEdition.currentGeb);
  const pastGebs = useSelector((state) => state.gebEdition.pastGebs);
  console.log('adminselected', selectedItem);
  useEffect(() => {
    fetchUpcommingGeb({ setUpcommingGeb, dispatch })
  }, []);

  useEffect(() => {
    fetchPastGeb({ setPastGeb, dispatch })
  }, []);

  const isCurrentRoute = (pathname, allowPartialMatch = false) => {
    if (allowPartialMatch) {
      return location.pathname.includes(pathname);
    }

    return location.pathname === pathname;
  };

  const handleNavigation = ({ goTo, id }) => {
    setSelectedItem(goTo);
    switch (goTo) {
      case 0:
        navigate("/admin/home");
        break;
      case 1:
        navigate("/admin/aboutGeb");
        break;
      case 2:
        if (id)
          navigate(`/admin/pastGeb/${id}`);
        break;
      case 3:
        navigate("/admin/upcommingGeb");
        break;
      case 4:
        navigate("/admin/organizers");
        break;
      case 5:
        navigate("/admin/faqs");
        break;
      case 6:
        navigate("/admin/applyNow");
        break;
      case 7:
        navigate("/admin/gallery");
        break;
      case 8:
        navigate("/admin/blogs");
        break;
      case 9:
        navigate("/admin/advisors");
        break;
      case 10:
        navigate("/admin/partnershipForm");
        break;
      case 11:
        navigate("/admin/visa");
        break;
      case 12:
        navigate("/admin/paymentInformation");
        break;
      case 13:
        navigate("/admin/funding");
        break;
      case 14:
        navigate("/admin/termsAndconditions");
        break;

      default:
        break;
    }
  };

  function refreshPage() {
    window.location.reload(false);
  }

  const pastEditionSelectionHandle = ({ id }) => {
    handleNavigation({ goTo: 2, id: id });
  }

  return (
    <div className="navbar_container sticky" >
      <div className="navbar_inner_wrapper" style={{ margin: "0 10rem" }} >
        <div className="navbar_logo">
          <Logo link={"home"} width={90} marginY={1} />
        </div>
        <nav className="navbar_contents">
          <ul className="navbar_links">
            <li className={`navbar_item ${isCurrentRoute("/admin/home") ? "active" : ""}`}>
              <Link to="/admin/home">Home</Link>
            </li>

            <li className={`navbar_item ${isCurrentRoute("/admin/aboutGeb") ? "active" : ""}`}>
              <Link to="/admin/aboutGeb">About</Link>
            </li>
            <li className={`navbar_dropdown ${isCurrentRoute("/pastGeb", true) ? "active" : ""}`}
              onMouseEnter={handleDropdownToggle}
              onMouseLeave={handleDropdownToggle}>
              <div className="navbar_item" >Past GEBs <ExpandMoreIcon /></div>
              {pastGebs ? <ul
                style={{
                  marginTop: "80px"
                }}
                className={`dropdown_menu ${dropdownOpen ? "show" : ""}`}>
                {
                  pastGebs.map((pastGeb, index) => {
                    var nth = `${pastGeb.editionNumber}th`;
                    if (pastGeb.editionNumber === 1) {
                      nth = `${pastGeb.editionNumber}st`
                    } else if (pastGeb.editionNumber === 2) {
                      nth = `${pastGeb.editionNumber}nd`
                    } else if (pastGeb.editionNumber === 3) {
                      nth = `${pastGeb.editionNumber}rd`
                    }

                    return (
                      <li key={index}
                        className={`dropdown_item ${pastGeb._id === params.id ? "active" : ""}`}>
                        <Link to={`/admin/pastGeb/${pastGeb._id}`}>
                          {`GEB ${pastGeb.eventLocation ?? "Dubai"}
                                             (${nth} Edition)`}
                        </Link>
                      </li>
                    );
                  })
                }
              </ul> : ""}
            </li>
            <li className={`navbar_item ${isCurrentRoute("/admin/upcommingGeb") ? "active" : ""}`}>
              <Link to="/admin/upcommingGeb">
                {currentGeb.editionNumber}th GEB  {currentGeb.eventLocation}
              </Link>
            </li>

            <li className={`navbar_item ${isCurrentRoute("/admin/organizers") ? "active" : ""}`}>
              <Link to="/admin/organizers">Organizers</Link>
            </li>
            <li className={`navbar_item ${isCurrentRoute("/admin/faqs") ? "active" : ""}`}>
              <Link to="/admin/faqs">FAQ</Link>
            </li>
            <li className={`navbar_item ${isCurrentRoute("/admin/applyNow") ? "active" : ""}`}>
              <Link to="/admin/applyNow">Apply Now</Link>
            </li>
            <li className={`navbar_dropdown`}
              onMouseEnter={handleDropdownToggle2}
              onMouseLeave={handleDropdownToggle2}>
              <div className="navbar_item" >More <ExpandMoreIcon /></div>
              <ul
                style={{
                  marginTop: "80px"
                }}
                className={`dropdown_menu ${dropdownOpen2 ? "show" : ""}`}>

                <li className={`dropdown_item ${isCurrentRoute("/admin/gallery") ? "active" : ""}`}>
                  <Link to="/admin/gallery">Gallery</Link>
                </li>
                <li className={`dropdown_item ${isCurrentRoute("/admin/blogs") ? "active" : ""}`}>
                  <Link to="/admin/blogs">Blogs</Link>
                </li>

                <li className={`dropdown_item ${isCurrentRoute("/admin/advisors") ? "active" : ""}`}>
                  <Link to="/admin/advisors">Advisors</Link>
                </li>
                <li className={`dropdown_item ${isCurrentRoute("/admin/partnershipForm") ? "active" : ""}`}>
                  <Link to="/admin/partnershipForm">Partnership Form</Link>
                </li>

                <li className={`dropdown_item ${isCurrentRoute("/admin/visa") ? "active" : ""}`}>
                  <Link to="/admin/visa">Visa</Link>
                </li>

                <li className={`dropdown_item ${isCurrentRoute("/admin/paymentInformation") ? "active" : ""}`}>
                  <Link to="/admin/paymentInformation">Payment Information</Link>
                </li>

                <li className={`dropdown_item ${isCurrentRoute("/admin/funding") ? "active" : ""}`}>
                  <Link to="/admin/funding">Funding</Link>
                </li>

                <li className={`dropdown_item ${isCurrentRoute("/admin/termsAndconditions") ? "active" : ""}`}>
                  <Link to="/admin/termsAndconditions">Terms And Conditions</Link>
                </li>
              </ul>
            </li>
          </ul>
          <div className="navbar_btn_container" >
            <div className="admin_navbar_btn navbar_btn" onClick={() => {
              navigate("/admin/profile")
            }}>Profile</div>
            <div onClick={() => {
              clearAllCookies();
              refreshPage();
            }} className="admin_navbar_btn navbar_btn">Logout</div>
            {(user?.role === "editor") ? "" : <div className="admin_navbar_btn navbar_btn" onClick={() => {
              navigate("/admin/editAdmin");
            }}>Edit Admin</div>}
          </div>
        </nav>
      </div>
    </div>
  );
};

export default AdminNavbar;
