import React, { Suspense } from 'react';
import { createBrowserRouter, Outlet } from 'react-router-dom';
import Navbar from "../components/Navbar/Navbar";
import AdminNavbar from "../components/AdminNavbar/AdminNavbar";
import ErrorElement from '../components/ErrorElement/ErrorElement';
import Footer from '../components/Footer/Footer';
import ProtectedPage from "../components/ProtectedPage/ProtectedPage";
import getPastGebById from "../packages/getPastGebById";
import LottieAnimation from "../components/LottieAnimation";
import fetchBlogById from "../pages/Admin/AddNewBlog/packages/fetchBlogById"
import fetchAllSpeakers from "../pages/clients/PastGeb/components/speakers/packages/fetchAllSpeakers"
import { GetUser } from "../packages/cookiesPackage/ManageUserState";

// Import important shared css
// TODO: organize css properly
import "../pages/Admin/AdminHomePage/components/EditableHomeBanner/EditableHomePage.css";
import "../pages/Admin/OrganizersPage/components/EditPopUp.css";
import "../pages/Admin/AddNewBlog/AddNewBlog.css";
import "../pages/clients/OrganizersPage/OrganizersPage.css"
import "../pages/clients/PastGeb/components/BusinessIdeas/BusinessIdeas.css";
import "../pages/clients/TermsAndCondition/styles.css";
import "../pages/clients/FaqPage/FaqPage.css";
import "../pages/clients/HomePage/components/SectionThree/SectionThree.css";
import "../pages/clients/AboutGeb/aboutGeb.css";

// Lazy load components
import AdminLogin from "../pages/Admin/AdminLogin/adminLogin";
const HomePage = React.lazy(() => import("../pages/clients/HomePage/homePage"));
const AdminHomePage = React.lazy(() => import("../pages/Admin/AdminHomePage/adminHome"));
const AdminOrganizerPage = React.lazy(() => import("../pages/Admin/OrganizersPage/OrganizersPage"));
const OrganizersPage = React.lazy(() => import("../pages/clients/OrganizersPage/organizersPage"));
const UpcommingGeb = React.lazy(() => import("../pages/clients/UpcommingGeb/UpcommingGeb"));
const AdminUpcommingGeb = React.lazy(() => import("../pages/Admin/AdminUpcommingGeb/AdminUpcommingGeb"));
const ContactsPage = React.lazy(() => import("../pages/clients/ContactsPage/contactsPage"));
const AboutGeb = React.lazy(() => import("../pages/clients/AboutGeb/aboutGeb"));
const PastGeb = React.lazy(() => import("../pages/clients/PastGeb/PastGeb"));
const AdminPastGeb = React.lazy(() => import("../pages/Admin/AdminPastGeb/AdminPastGeb"));
const AdminAboutPage = React.lazy(() => import("../pages/Admin/AdminAboutPage/AdminAboutPage"));
const AdminFaqsPage = React.lazy(() => import('../pages/Admin/AdminFaqsPage/AdminFaqsPage'));
const AdminProfile = React.lazy(() => import("../pages/Admin/AdminProfle/AdminProfile"));
const EditAdmin = React.lazy(() => import("../pages/Admin/EditAdmin/EditAdmin"));
const FaqPage = React.lazy(() => import("../pages/clients/FaqPage/FaqPage"));
const ApplyNow = React.lazy(() => import("../pages/clients/ApplyNow/ApplyNow"));
const AdminApplyNow = React.lazy(() => import("../pages/Admin/AdminApplyNow/AdminApplyNow"));
const Funding = React.lazy(() => import("../pages/clients/Funding/Funding"));
const PaymentInfromation = React.lazy(() => import("../pages/clients/PaymentInfromation/PaymentInfromation"));
const TermsAndCondition = React.lazy(() => import("../pages/clients/TermsAndCondition/TermsAndCondition"));
const VisaPage = React.lazy(() => import("../pages/clients/Visa/VisaPage"));
const BlogPage = React.lazy(() => import("../pages/clients/Blog/Blog"));
const AddNewBlog = React.lazy(() => import("../pages/Admin/AddNewBlog/AddNewBlog"));
const BlogDetail = React.lazy(() => import("../pages/clients/BlogDetail/BlogDetail"));
const Advisors = React.lazy(() => import("../pages/clients/Advisors/Advisors"));
const PartnershipFormPage = React.lazy(() => import("../pages/clients/PartnershipFormPage/PartnershipFormPage"));
const SpeakerDetail = React.lazy(() => import("../pages/clients/SpeakerDetail/SpeakerDetail"));
const PartnersPage = React.lazy(() => import("../pages/clients/PartnersPage/PartnersPage"));
const GebPastSpeakers = React.lazy(() => import("../pages/clients/GebPastSpeakers/GebPastSpeakers"));
const EditableExtraPages = React.lazy(() => import("../pages/Admin/EditableExtraPages/EditableExtraPages"));
const Gallery = React.lazy(() => import("../pages/clients/Gallery/Gallery"));
const VentureFund = React.lazy(() => import('../pages/clients/VentureFund/ventureFund'));
const SVEPMain = React.lazy(() => import('../pages/clients/svep'));
const SVEPCorporate = React.lazy(() => import('../pages/clients/svep/corporate'));
const SVEPStudent = React.lazy(() => import('../pages/clients/svep/student'));

const user = GetUser();

const adminRouteElements = [
  {
    path: "",
    index: true,
    element: (
      <ProtectedPage pageToBeProtected={<AdminLogin />} isLoginPage={true} />
    ),
  },
  {
    path: "profile",
    index: true,
    element: (
      <ProtectedPage pageToBeProtected={<AdminProfile />} isLoginPage={false} />
    )
  },
  {
    path: "editAdmin",
    index: true,
    element: (
      <ProtectedPage pageToBeProtected={<EditAdmin />} isLoginPage={false} />
    )
  },
  {
    path: "home",
    index: true,
    element: <ProtectedPage pageToBeProtected={<AdminHomePage />} />,
  },
  {
    path: "aboutGeb",
    index: true,
    element: <ProtectedPage pageToBeProtected={<AdminAboutPage />} />,
  },

  {
    path: "pastGeb/:id",
    element: <ProtectedPage pageToBeProtected={<AdminPastGeb />} />,
    loader: async ({ params }) => {
      let data = await getPastGebById({ id: params.id });
      return data;
    },
  },
  {
    path: "gallery",
    element: <ProtectedPage pageToBeProtected={<>
      <Suspense fallback={
        <LottieAnimation />
      }>
        <Gallery isAdmin={true} />
      </Suspense>
    </>} />,
  },
  {
    path: "upcommingGeb",
    index: true,
    element: <ProtectedPage pageToBeProtected={<AdminUpcommingGeb />} />,
  },
  {
    path: "organizers",
    index: true,
    element: <ProtectedPage pageToBeProtected={<AdminOrganizerPage />} />,
  },
  {
    path: "faqs",
    index: true,
    element: <ProtectedPage pageToBeProtected={<AdminFaqsPage />} />,
  },
  {
    path: "applyNow",
    element: <ProtectedPage pageToBeProtected={<AdminApplyNow />} />,
  },
  {
    path: "partnershipForm",
    element: <ProtectedPage pageToBeProtected={<PartnershipFormPage isAdmin={true} />} />,
  },
  {
    path: "blogs",
    element: <ProtectedPage pageToBeProtected={<BlogPage isAdmin={true} />} />,
  },
  {
    path: "advisors",
    element: <ProtectedPage pageToBeProtected={<Advisors isAdmin={true} />} />,
  },
  {
    path: "addNewBlog",
    element: <ProtectedPage pageToBeProtected={<AddNewBlog />} />,
  },
  {
    path: "visa",
    element: <ProtectedPage pageToBeProtected={<EditableExtraPages pageTitle={"visa"} />} />,
  },
  {
    path: "paymentInformation",
    element: <ProtectedPage pageToBeProtected={<EditableExtraPages pageTitle={"payment"} />} />,
  },
  {
    path: "funding",
    element: <ProtectedPage pageToBeProtected={<EditableExtraPages pageTitle={"funding"} />} />,
  },
  {
    path: "termsAndconditions",
    element: <ProtectedPage pageToBeProtected={<EditableExtraPages pageTitle={"terms"} />} />,
  },
];

const clientRouteElements = [
  //for home page
  {
    path: "/",
    index: true,
    element: <HomePage />,
  },
  //for about page
  {
    path: "/aboutGeb",
    element: <AboutGeb />,
  },
  //for past geb page
  {
    path: "/pastGeb/:id",
    element: <PastGeb />,
    loader: async ({ params }) => {
      let data = await getPastGebById({ id: params.id });
      return data;
    },
  },
  {
    path: "/speakerDetail/:id",
    element: <SpeakerDetail />,
    loader: async ({ params }) => {
      let data = await fetchAllSpeakers({ editionId: params.id });
      return data;
    },
  },
  {
    path: "/gebPastSpeakers",
    element: <GebPastSpeakers />,
  },
  //for gallery page
  {
    path: "/gallery",
    element: <Gallery />
  },
  //for upcomming page
  {
    path: "/upcommingGeb",
    element: <UpcommingGeb />,
  },
  //for organizers page
  {
    path: "/organizers",
    element: <OrganizersPage />,
  },
  {
    path: "/contacts",
    element: <ContactsPage />,
  },
  {
    path: "/faqs",
    element: <FaqPage />
  },
  {
    path: "/applyNow",
    element: <ApplyNow />,
  },
  {
    path: "/funding",
    element: <Funding />,
  },
  {
    path: "/paymentInformation",
    element: <PaymentInfromation />,
  },
  {
    path: "/partnershipForm",
    element: <PartnershipFormPage />,
  },
  {
    path: "/visa",
    element: <VisaPage />,
  },
  {
    path: "/blog",
    element: <BlogPage />,
  },
  //for past geb page
  {
    path: "/blogDetail/:id",
    element: <BlogDetail />,
    loader: async ({ params }) => {
      let data = await fetchBlogById({ id: params.id });
      // console.log("data got = ", data)
      return data;
    },
  },
  {
    path: "/advisors",
    element: <Advisors />,
  },
  {
    path: "/partners",
    element: <PartnersPage />,
  },
  {
    path: "/termsAndCondition",
    element: <TermsAndCondition />,
  },
  {
    path: "/geb-venture-fund",
    element: <VentureFund />,
  },
  {
    path: "/silicon-valley-exchange-program",
    element: <SVEPMain />,
  },
  {
    path: "/svep-corporate",
    element: <SVEPCorporate />,
  },
  {
    path: "/svep-student",
    element: <SVEPStudent />,
  },
];

const router = createBrowserRouter([
  //for Clients...
  {
    path: "/",
    element: (
      <>
        {user ? <AdminNavbar /> : <Navbar />}
        <Suspense fallback={<LottieAnimation />}>
          <Outlet />
        </Suspense>
        <Footer />
      </>
      // <LinkShareImg childComponent={
      //   <>
      //     <Outlet />
      //     <Footer />
      //   </>
      // }
      // />
    ),
    errorElement: <ErrorElement />,
    children: [...clientRouteElements],
  },

  //for Admin...
  {
    path: "admin",
    element: (
      <>
        {user ? <AdminNavbar /> : <Navbar />}
        <Suspense fallback={<LottieAnimation />}>
          <Outlet />
        </Suspense>
        {/* <Footer /> */}
      </>
    ),
    errorElement: <ErrorElement />,
    children: [...adminRouteElements],
  },
]);

export default router;
